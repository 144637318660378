<template>
  <div>
    <EmployeeDailyAttendanceTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
      @expandDetails="showClockRecord"
      @showManageModal="showManageModal"
      @changePage="handlePageChange"
      @showUpdateModal="showUpdateModal"
    ></EmployeeDailyAttendanceTable>

    <EmployeePageClockRecordModal
      v-bind:attendance-id="attendanceId"
    ></EmployeePageClockRecordModal>

    <EmployeeAttendanceManageModal
      @formSubmitted="fetch"
    ></EmployeeAttendanceManageModal>

    <EmployeeAttendanceUpdateTimeModal
      :employee="employee"
      :attendance="attendance"
      @formSubmitted="fetch"
    ></EmployeeAttendanceUpdateTimeModal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import EmployeePageClockRecordModal from "@/modules/company/components/employees/EmployeePageClockRecordModal";
import EmployeeAttendanceManageModal from "@/modules/company/components/attendance/manage/EmployeeAttendanceManageModal";
import EmployeeDailyAttendanceTable from "@/modules/company/components/shared/attendance/daily/EmployeeDailyAttendanceTable";
import EmployeeAttendanceUpdateTimeModal from "@/modules/company/components/attendance/manage/time/EmployeeAttendanceUpdateTimeModal";

export default {
  name: "EmployeeDailyAttendance",
  components: {
    EmployeeAttendanceUpdateTimeModal,
    EmployeeDailyAttendanceTable,
    EmployeeAttendanceManageModal,
    EmployeePageClockRecordModal,
  },
  data() {
    return {
      attendanceId: null,
      employee: {},
      attendance: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("fetchEmployeeDailyAttendance");
    },
    /**
     * Show clock record.
     *
     * @param data
     * @returns {null}
     */
    showClockRecord(data) {
      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk(
          "Employee has to be checked in first for their clock records to appear",
          {
            title: "Attendance is empty",
            centered: true,
          }
        );
        return null;
      }
      this.attendanceId = data.item.attendance ? data.item.attendance.id : null;
      this.$bvModal.show("employee-page-clock-record-modal");
    },

    /**
     * Show modal.
     *
     * @param data
     */
    showManageModal(data) {
      console.log(data);
      this.$store.commit("setDailyEmployeeAttendanceFormState", false);
      this.$store.dispatch("assignDailyEmployeeAttendance", {
        data: data.item,
        date: dayjs(new Date()).format(),
      });
      this.$bvModal.show("emp-attendance-manage-modal");
    },

    showUpdateModal(data) {
      this.employee = data.item;
      console.log("Employee", this.employee);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk("Employee doesnt have attendance", {
          title: "Attendance is empty",
          centered: true,
        });
        return null;
      }

      this.attendance = data.item.attendance;
      console.log("Attendance", this.attendance);

      this.$bvModal.show("common-attendance-update-time-modal");
    },

    /**
     *
     * @param page
     */
    handlePageChange(page) {
      this.$store.commit("setEmployeeDailyAttendancePage", page);
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getEmployeeDailyAttendanceList",
      pagination: "getEmployeeDailyAttendancePagination",
      isBusyStore: "getEmployeeDailyAttendanceBusy",
    }),
  },
};
</script>

<style scoped></style>
